import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';


@Component({
  components: {}
})
export default class UnAssignSiteComponent extends Vue {

  private objScreenText: ScreenText = new ScreenText();


  @Prop()
  userDetails!: any;

  @Prop()
  roleId!: number;

  @Watch('userDetails', { deep: true, immediate: true })


  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }
}