











import DataDashboardComponent from "@/components/unassignsitecomponent/UnAssignSiteComponent";
export default DataDashboardComponent;
